import React from "react";
import MainLayout from "../Layouts/MainLayout";

function withLayout<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  LayoutComponent: React.ComponentType<any> = MainLayout,
  navHideEffect = true
) {
  return (props: P) => {
    return (
      <LayoutComponent {...props} navHideEffect={navHideEffect}>
        <WrappedComponent {...props} />
      </LayoutComponent>
    );
  };
}

export default withLayout;
