import React from "react";
import { graphql } from "gatsby";

import withLayout from "../../hoc/withLayout/withLayout";

import BlogPost from "../../components/BlogPost/BlogPost";
import { BlogStructuredData } from "../../components";

export interface BlogPostType {
  title?: string;
  feature_image?: string;
  excerpt?: string;
  reading_time?: number;
  published_at?: string;
  created_at?: string;
  updated_at?: string;
  primary_tag?: any;
  og_title?: string;
  og_description?: string;
  og_image?: string;
  meta_description: string;
  html?: string;
  authors?: {
    name: string;
  }[];
}

interface BlogPostProps {
  data: {
    ghostPost: BlogPostType;
  };
  location: { href: string };
}

const BlogPostTemplate = ({ data, location: { href } }: BlogPostProps) => {
  const post = data.ghostPost;

  return (
    <>
      <BlogStructuredData
        title={post.title}
        location={href}
        image={post.feature_image}
        dateModified={post.updated_at}
        datePublished={post.published_at}
      />
      <BlogPost post={post} nextArticle={{}} />
    </>
  );
};

export const postQuery = graphql`
  query POST_QUERY($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
  }
`;

export default withLayout(BlogPostTemplate);
